export default () => next => action => {
  // Connexion à la BDD AIDE
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    // On n'a pas réussi à accéder à la BDD (l'utilisateur a refusé l'accès)
    // On affiche un message et le code erreur dans la console
    console.log('AIDE erreur', event.target.errorCode);
  };

  request.onupgradeneeded = event => {
    // On a accès la ba BDD mais il faut la recréer
    const db = event.target.result;

    // On crée l'emplacement de sauvegarde pour les intervenants
    const userObjectStore = db.createObjectStore('user', { keyPath: 'id' });

    // On ajoute un index pour les recherches
    userObjectStore.createIndex('id', 'id', { unique: true });

    // On crée l'emplacement de sauvegarde pour les messages
    const messageObjectStore = db.createObjectStore('message', { keyPath: 'id' });

    // On ajoute un index pour les recherches
    messageObjectStore.createIndex('id', 'id', { unique: true });

    // On crée l'emplacement de sauvegarde pour les RDV
    const planningObjectStore = db.createObjectStore('planning', { keyPath: 'date' });

    // On ajoute un index pour les recherches
    planningObjectStore.createIndex('date', 'date', { unique: true });

    planningObjectStore.transaction.oncomplete = () => {
      // La création de l'emplacement s'est bien passée

      switch (action.type) {
        default:
          break;
      }
    };
  };

  request.onsuccess = () => {
    switch (action.type) {
      default:
        break;
    }
  };

  next(action);
};
