import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  CONFIG_LOAD_SUCCESS,
  CONFIG_LOAD_FAILURE
} from '../constants/ActionTypes';

export const tmp = () => {};

export function* loadConfig() {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_LOAD_REQUEST Offline');
  } else {
    try {
      const LOAD_CONFIG = gql`
        {
          loadConfig {
            googleApiKey
          }
        }
      `;
      const response = yield call(fetchApollo, LOAD_CONFIG);
      const config = response.data.loadConfig;

      if (config !== null) {
        yield put({ type: CONFIG_LOAD_SUCCESS, payload: config });
      } else {
        yield put({ type: CONFIG_LOAD_FAILURE, payload: 'Erreur de chargement de la config' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement de la config';
          break;
        default:
          break;
      }

      yield put({ type: CONFIG_LOAD_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}
