import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import { loadConfig } from '../actions/config';
import { addView, activateView } from '../actions/view';
import { loadIntervenantMeeting } from '../actions/intervenant';
import { loadChat, loadUnreadChat } from '../actions/chat';
import { setUnloggedUser } from '../actions/user';
import { subscribe } from '../components/Notification/Notification';

import ViewsList from '../components/Core/Layout/View/List';
import TopBar from '../components/Core/Layout/TopBar';
import TopPane from '../components/Core/Layout/Pane/TopPane';
import FilterPane from '../components/Core/Layout/Pane/FilterPane';
import MainTitle from '../components/Core/Layout/Title/H1';
import PaneToggleButton from '../components/Core/Layout/Btn/Toggle';

import AdsMap from '../components/Map/Map';
import MeetingList from '../components/List/MeetingList';
import IntervenantList from '../components/List/IntervenantList';
import LocateIntervenant from '../components/LocateIntervenant/LocateIntervenant';
import SmartChat from '../components/Chat';

const Screen = styled.div`
  background: ${props => props.theme.colors.grey};
`;

const PaneWrapper = styled.div`
  height: 100%;
  background: ${props => props.theme.colors.backgroundLight};
`;

class Planification extends Component {
  constructor() {
    super();

    this.mounted = false;

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,

      topBarPane: {
        size: 40
      },

      bottomBarPane: {
        size: 48
      },

      filterPane: {
        minSize: 40,
        maxSize: 300,
        size: 300,
        opened: true,
        onStopResize: this.onFilterPaneStopResize
      },

      topPane: {
        minSize: 120,
        maxSize: 120,
        size: 120
      },

      mainPane: {
        minSize: window.innerWidth / 2,
        maxSize: window.innerWidth - 444,
        size: window.innerWidth - 444
      },

      detailsPane: {
        size: 0,
        minSize: 400,
        maxSize: window.innerWidth / 3
      },

      activeMarker: 0,

      modalOpen: false
    };

    this.resizeProps = {
      onStopResize: this.onStopResize.bind(this),
      onResize: this.onResize.bind(this)
    };

    this.notificationsContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { dispatch, user } = this.props;
    this.mounted = true;
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      subscribe(user).catch(err => console.error(err));
    }
    dispatch(loadConfig());
    dispatch(loadUnreadChat(user.iPKUtilisateur));
    setInterval(() => {
      dispatch(loadUnreadChat(user.iPKUtilisateur));
    }, parseInt(process.env.REACT_APP_DELAY_LOAD_UNREAD_MESSAGE, 0));
    window.addEventListener('beforeunload', this.doSomethingBeforeUnload);
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.updateDimensions();
  };

  componentWillUnmount() {
    this.mounted = false;
    const { dispatch, user } = this.props;
    dispatch(setUnloggedUser(user.iPKUtilisateur));
    window.addEventListener('beforeunload', this.doSomethingBeforeUnload);
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  // Things to do before unloading/closing the tab
  doSomethingBeforeUnload = evt => {
    const { dispatch, user } = this.props;
    evt.preventDefault();
    dispatch(setUnloggedUser(user.iPKUtilisateur));
    setTimeout(() => true, 1500);
  };

  onResize = () => {};

  onStopResize = () => {};

  onFilterPaneStopResize = event => {
    const { filterPane } = this.state;
    const { minSize, maxSize } = filterPane;
    const size = event.domElement.clientWidth;

    if (size <= minSize) {
      this.setState(prevState => ({
        filterPane: Object.assign({}, prevState.filterPane, { size: minSize, opened: false })
      }));
    } else if (size > minSize) {
      this.setState(prevState => ({
        filterPane: Object.assign({}, prevState.filterPane, { size: maxSize, opened: true })
      }));
    }
  };

  onBtnToggleFilterPaneClick = event => {
    event.preventDefault();

    const { filterPane } = this.state;

    if (filterPane.opened) {
      this.closePane(filterPane);
    } else {
      this.openPane(filterPane);
    }
  };

  onBtnNotificationCloseClick = id => {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(notification => notification.id !== id)
    }));
  };

  updateDimensions = () => {
    if (this.mounted) {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  handleOpen = evt => {
    evt.preventDefault();
    this.setState({ modalOpen: true });
  };

  handleClose = () => this.setState({ modalOpen: false });

  openPane = pane => {
    const { size, maxSize } = pane;
    const counter = { var: size };

    TweenMax.to(counter, 0.5, {
      var: maxSize,
      onUpdate: () =>
        this.setState(prevState => ({
          filterPane: Object.assign({}, prevState.filterPane, {
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize
          })
        }))
    });
  };

  closePane = pane => {
    const { size, minSize, maxSize } = pane;
    const counter = { var: size };

    TweenMax.to(counter, 0.5, {
      var: minSize,
      onUpdate: () =>
        this.setState(prevState => ({
          filterPane: Object.assign({}, prevState.filterPane, {
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize
          })
        }))
    });
  };

  selectMarker = iPkRdv => {
    const { activeMarker } = this.state;
    if (activeMarker !== iPkRdv) {
      this.setState({ activeMarker: iPkRdv });
    }
  };

  selectIntervenant = item => {
    const { dispatch, views, user } = this.props;
    const existView = views.tVue.filter(view => view.iPKVue === item.iPKAnnuaire);

    if (existView.length === 0) {
      dispatch(
        addView(item.iPKAnnuaire, item.sNomPrenom, {
          rLatitude: item.rLatitude,
          rLongitude: item.rLongitude
        })
      );
    }
    dispatch(loadChat(user.iPKUtilisateur, item.iPKAnnuaire));
    dispatch(
      activateView({
        iPKVue: item.iPKAnnuaire,
        iPKAnnuaire: item.iPKAnnuaire,
        sLabel: item.sNomPrenom,
        tPos: {
          rLatitude: item.rLatitude,
          rLongitude: item.rLongitude
        }
      })
    );
    dispatch(loadIntervenantMeeting(item.iPKAnnuaire));
  };

  render() {
    const {
      width,
      height,
      topBarPane,
      bottomBarPane,
      filterPane,
      topPane,
      mainPane,
      detailsPane,
      activeMarker,
      modalOpen
    } = this.state;
    const { tIntervenant, tMeeting, views, config } = this.props;
    return (
      <Screen>
        <ReflexContainer
          windowResizeAware
          orientation="horizontal"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <ReflexElement className="header" size={40}>
            <TopBar notificationsContainer={this.notificationsContainerRef.current} />
          </ReflexElement>

          <ReflexElement propagateDimensionsRate={200} propagateDimensions>
            <ReflexContainer
              windowResizeAware
              orientation="vertical"
              style={{
                width: `${width}px`,
                height: `${height - (topBarPane.size + bottomBarPane.size + 2 + 2)}px`
              }}
            >
              <ReflexElement {...filterPane}>
                <PaneWrapper>
                  <PaneToggleButton
                    opened={filterPane.opened}
                    onClick={this.onBtnToggleFilterPaneClick}
                  />
                  <FilterPane
                    opened={filterPane.opened}
                    handleOpen={this.handleOpen}
                    onBtnToggleFilterPaneClick={this.onBtnToggleFilterPaneClick}
                    onCreateViewClick={this.onCreateViewClick}
                  />
                  {tIntervenant && typeof tIntervenant === 'object' && filterPane.opened && (
                    <IntervenantList
                      tIntervenant={tIntervenant}
                      selectIntervenant={this.selectIntervenant}
                    />
                  )}
                </PaneWrapper>
              </ReflexElement>

              <ReflexSplitter {...this.resizeProps} />

              <ReflexElement propagateDimensionsRate={200} propagateDimensions>
                <ReflexContainer windowResizeAware orientation="horizontal">
                  <ReflexElement {...topPane}>
                    <TopPane>
                      <MainTitle>Planification des tournées</MainTitle>
                      <ViewsList />
                    </TopPane>
                  </ReflexElement>

                  <ReflexElement propagateDimensionsRate={200} propagateDimensions>
                    <ReflexContainer windowResizeAware orientation="vertical">
                      <ReflexElement flex={1} {...mainPane} {...this.resizeProps}>
                        <AdsMap
                          searchIntervenant={false}
                          googleApiKey={
                            process.env.REACT_APP_GOOGLE_API === 'true' && config.googleApiKey
                              ? config.googleApiKey
                              : ''
                          }
                          lat={
                            views.selected.tPos && views.selected.tPos.rLatitude
                              ? views.selected.tPos.rLatitude
                              : 47.546
                          }
                          lng={
                            views.selected.tPos && views.selected.tPos.rLongitude
                              ? views.selected.tPos.rLongitude
                              : 2.597
                          }
                          activeMarker={activeMarker}
                          intervenant={views.selected.sLabel ? views.selected.sLabel : ''}
                          intervenantMarker={
                            views.selected.tPos &&
                            views.selected.tPos.rLatitude &&
                            views.selected.tPos.rLongitude
                              ? [views.selected.tPos.rLatitude, views.selected.tPos.rLongitude]
                              : []
                          }
                          tMeetingMarkers={tMeeting}
                        />
                      </ReflexElement>

                      <ReflexSplitter {...this.resizeProps} />

                      <ReflexElement {...detailsPane} {...this.resizeProps}>
                        <MeetingList tMeeting={tMeeting} selectMarker={this.selectMarker} />
                      </ReflexElement>
                    </ReflexContainer>
                  </ReflexElement>
                </ReflexContainer>
              </ReflexElement>
            </ReflexContainer>
          </ReflexElement>

          <ReflexElement className="footer" size={48}>
            <SmartChat />
          </ReflexElement>
        </ReflexContainer>
        <LocateIntervenant modalOpen={modalOpen} handleClose={this.handleClose} />
      </Screen>
    );
  }
}

Planification.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tIntervenant: PropTypes.arrayOf(PropTypes.object).isRequired,
  views: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  tMeeting: PropTypes.arrayOf(PropTypes.object),
  tSearchedIntervenant: PropTypes.arrayOf(PropTypes.object)
};

Planification.defaultProps = {
  tMeeting: [],
  tSearchedIntervenant: []
};

const mapStateToProps = state => ({
  tIntervenant: state.intervenant.list,
  tSearchedIntervenant: state.intervenant.search,
  tMeeting: state.intervenant.meetings || [],
  config: state.config,
  views: state.view,
  user: state.user.data
});

export default connect(mapStateToProps)(Planification);
