export const NETWORK_ONLINE_REQUEST = 'NETWORK_ONLINE_REQUEST';
export const NETWORK_ONLINE_SUCCESS = 'NETWORK_ONLINE_SUCCESS';
export const NETWORK_ONLINE_FAILURE = 'NETWORK_ONLINE_FAILURE';

export const NETWORK_OFFLINE_REQUEST = 'NETWORK_OFFLINE_REQUEST';
export const NETWORK_OFFLINE_SUCCESS = 'NETWORK_OFFLINE_SUCCESS';
export const NETWORK_OFFLINE_FAILURE = 'NETWORK_OFFLINE_FAILURE';

export const LOADING_ACTIVATE_REQUEST = 'LOADING_ACTIVATE_REQUEST';
export const LOADING_ACTIVATE_SUCCESS = 'LOADING_ACTIVATE_SUCCESS';
export const LOADING_ACTIVATE_FAILURE = 'LOADING_ACTIVATE_FAILURE';

export const LOADING_DEACTIVATE_REQUEST = 'LOADING_DEACTIVATE_REQUEST';
export const LOADING_DEACTIVATE_SUCCESS = 'LOADING_DEACTIVATE_SUCCESS';
export const LOADING_DEACTIVATE_FAILURE = 'LOADING_DEACTIVATE_FAILURE';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE';

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE';

export const USER_LOGGED_REQUEST = 'USER_LOGGED_REQUEST';
export const USER_LOGGED_FAILURE = 'USER_LOGGED_FAILURE';

export const USER_UNLOGGED_REQUEST = 'USER_UNLOGGED_REQUEST';
export const USER_UNLOGGED_FAILURE = 'USER_UNLOGGED_FAILURE';

export const CONFIG_LOAD_REQUEST = 'CONFIG_LOAD_REQUEST';
export const CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS';
export const CONFIG_LOAD_FAILURE = 'CONFIG_LOAD_FAILURE';

export const VIEW_LIST_REQUEST = 'VIEW_LIST_REQUEST';
export const VIEW_LIST_SUCCESS = 'VIEW_LIST_SUCCESS';
export const VIEW_LIST_FAILURE = 'VIEW_LIST_FAILURE';

export const VIEW_ADD_REQUEST = 'VIEW_ADD_REQUEST';
export const VIEW_ADD_SUCCESS = 'VIEW_ADD_SUCCESS';
export const VIEW_ADD_FAILURE = 'VIEW_ADD_FAILURE';

export const VIEW_EDIT_REQUEST = 'VIEW_EDIT_REQUEST';
export const VIEW_EDIT_SUCCESS = 'VIEW_EDIT_SUCCESS';
export const VIEW_EDIT_FAILURE = 'VIEW_EDIT_FAILURE';

export const VIEW_DELETE_REQUEST = 'VIEW_DELETE_REQUEST';
export const VIEW_DELETE_SUCCESS = 'VIEW_DELETE_SUCCESS';
export const VIEW_DELETE_FAILURE = 'VIEW_DELETE_FAILURE';

export const VIEW_ACTIVATE_REQUEST = 'VIEW_ACTIVATE_REQUEST';
export const VIEW_ACTIVATE_SUCCESS = 'VIEW_ACTIVATE_SUCCESS';
export const VIEW_ACTIVATE_FAILURE = 'VIEW_ACTIVATE_FAILURE';

export const VIEW_DEACTIVATE_REQUEST = 'VIEW_DEACTIVATE_REQUEST';
export const VIEW_DEACTIVATE_SUCCESS = 'VIEW_DEACTIVATE_SUCCESS';
export const VIEW_DEACTIVATE_FAILURE = 'VIEW_DEACTIVATE_FAILURE';

export const INTERVENANT_LOAD_REQUEST = 'INTERVENANT_LOAD_REQUEST';
export const INTERVENANT_LOAD_SUCCESS = 'INTERVENANT_LOAD_SUCCESS';
export const INTERVENANT_LOAD_FAILURE = 'INTERVENANT_LOAD_FAILURE';

export const INTERVENANT_MEETING_LOAD_REQUEST = 'INTERVENANT_MEETING_LOAD_REQUEST';
export const INTERVENANT_MEETING_LOAD_SUCCESS = 'INTERVENANT_MEETING_LOAD_SUCCESS';
export const INTERVENANT_MEETING_LOAD_FAILURE = 'INTERVENANT_MEETING_LOAD_FAILURE';

export const INTERVENANT_PATIENT_REQUEST = 'INTERVENANT_PATIENT_REQUEST';
export const INTERVENANT_PATIENT_SUCCESS = 'INTERVENANT_PATIENT_SUCCESS';
export const INTERVENANT_PATIENT_FAILURE = 'INTERVENANT_PATIENT_FAILURE';

export const CHAT_LOAD_REQUEST = 'CHAT_LOAD_REQUEST';
export const CHAT_LOAD_SUCCESS = 'CHAT_LOAD_SUCCESS';
export const CHAT_LOAD_FAILURE = 'CHAT_LOAD_FAILURE';

export const CHAT_DESACTIVE_CONVERSATION_REQUEST = 'CHAT_DESACTIVE_CONVERSATION_REQUEST';

export const CHAT_UNREAD_MESSAGE_LOAD_REQUEST = 'CHAT_UNREAD_MESSAGE_LOAD_REQUEST';
export const CHAT_UNREAD_MESSAGE_LOAD_SUCCESS = 'CHAT_UNREAD_MESSAGE_LOAD_SUCCESS';
export const CHAT_UNREAD_MESSAGE_LOAD_FAILURE = 'CHAT_UNREAD_MESSAGE_LOAD_FAILURE';

export const CHAT_SEND_MESSAGE_REQUEST = 'CHAT_SEND_MESSAGE_REQUEST';
export const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT_SEND_MESSAGE_SUCCESS';
export const CHAT_SEND_MESSAGE_FAILURE = 'CHAT_SEND_MESSAGE_FAILURE';

export const CHAT_RECEIVED_REQUEST = 'CHAT_RECEIVED_REQUEST';
export const CHAT_RECEIVED_FAILURE = 'CHAT_RECEIVED_FAILURE';

export const CHAT_READ_MESSAGE_REQUEST = 'CHAT_READ_MESSAGE_REQUEST';
export const CHAT_READ_MESSAGE_SUCCESS = 'CHAT_READ_MESSAGE_SUCCESS';
export const CHAT_READ_MESSAGE_FAILURE = 'CHAT_READ_MESSAGE_FAILURE';
