const themes = {
  adene: {
    colorPrimary: '#4bacce', // Petrol Blue
    colorPrimaryActive: '#93c90e', // Green
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#d6ef97', // light green
    colorBackgroundLighter: '#e6f9b6' // very light green
  },

  adep: {
    colorPrimary: '#539dbe', // Petrol Blue
    colorPrimaryActive: '#f08c00', // Orange
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#b9d9e8', // light green
    colorBackgroundLighter: '#cbe4ef' // very light green
  },

  // agir: {
  //   colorPrimary: '#0097ae', // Blue
  //   colorPrimaryActive: '#bccd00', // Green
  //   colorOtherClear: '#58a3ca', // Blue
  //   colorBorderDark: '#a29999', // Grey
  //   colorBorderLight: '#beb5b6', // Grey light
  //   colorBorderLighter: '#eff0f1', // Grey very light
  //   colorWhite: '#ffffff', // White
  //   colorBlack: '#413b3b', // Black
  //   colorRed: '#EE0000', // Red
  //   colorGreen: '#CDD329', // Green
  //   colorOrange: '#FD6A02', // Tiger
  //   colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
  //   colorBackgroundLight: '#a2dce5', // light green
  //   colorBackgroundLighter: '#beedf4' // very light green
  // },
  agir: {
    colors: {
      // Couleurs du logo
      blueAGIR: 'rgb(0, 151, 174)',
      blueBASELINE: 'rgb(12, 102, 124)',
      greyADOM: 'rgb(88, 88, 88)',

      blue: 'rgb(57, 178, 198)',
      orange: 'rgb(248, 179, 52)',
      purple: 'rgb(161, 13, 89)',
      purpleBackgroundLighter: 'rgba(161, 13, 89, 0.1)',
      purpleBackgroundLight: 'rgba(161, 13, 89, 0.25)',
      purpleDark: 'rgb(141, 0, 69)',
      blueDark: 'rgb(0, 151, 174)',
      greenLight: 'rgb(223, 219, 0)',
      greenDark: 'rgb(62, 167, 28)',
      yellow: 'rgb(252, 234, 13)',
      white: 'rgb(255, 255, 255)',
      grey: 'rgb(214, 215, 216)',
      textBlack: 'rgb(88, 88, 88)',

      inputBorderLight: 'rgb(222, 222, 222)',
      inputBorderDark: 'rgb(178, 179, 179)',

      borderLight: 'rgba(34,36,38,.15)',

      tableRowEven: 'rgba(57, 178, 198, 0.1)',
      tableRowEvenHover: 'rgba(57, 178, 198, 0.25)',
      tableRowSelected: 'rgb(168, 216, 227)',
      tableRowUneven: 'transparent',

      backgroundLight: 'rgb(241, 242, 246)',
      backgroundLighter: 'rgb(251, 252, 255)'
    }
  },

  airpartenairesante: {
    colorPrimary: '#7a716b', // Dark Grey
    colorPrimaryActive: '#2dbdd4', // Blue
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#adebf4', // light green
    colorBackgroundLighter: '#cef8ff' // very light green
  },

  airra: {
    colorPrimary: '#303490', // Blue
    colorPrimaryActive: '#0085c8', // Dark Blue
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#badff2', // light green
    colorBackgroundLighter: '#cfebf9' // very light green
  },

  alair: {
    colorPrimary: '#6749a5', // Dark Violet
    colorPrimaryActive: '#ec7505', // Orange
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#f2e8f3', // light green
    colorBackgroundLighter: '#f6f1f7' // very light green
  },

  asten: {
    colors: {
      // Couleurs du logo
      blueAGIR: 'rgb(0, 151, 174)',
      blueBASELINE: 'rgb(12, 102, 124)',
      greyADOM: 'rgb(88, 88, 88)',

      blue: 'rgb(57, 178, 198)',
      orange: 'rgb(248, 179, 52)',
      purple: 'rgb(161, 13, 89)',
      purpleBackgroundLighter: 'rgba(161, 13, 89, 0.1)',
      purpleBackgroundLight: 'rgba(161, 13, 89, 0.25)',
      purpleDark: 'rgb(141, 0, 69)',
      blueDark: 'rgb(0, 151, 174)',
      greenLight: 'rgb(223, 219, 0)',
      greenDark: 'rgb(62, 167, 28)',
      yellow: 'rgb(252, 234, 13)',
      white: 'rgb(255, 255, 255)',
      grey: 'rgb(214, 215, 216)',
      textBlack: 'rgb(88, 88, 88)',

      inputBorderLight: 'rgb(222, 222, 222)',
      inputBorderDark: 'rgb(178, 179, 179)',

      borderLight: 'rgba(34,36,38,.15)',

      tableRowEven: 'rgba(57, 178, 198, 0.1)',
      tableRowEvenHover: 'rgba(57, 178, 198, 0.25)',
      tableRowSelected: 'rgb(168, 216, 227)',
      tableRowUneven: 'transparent',

      backgroundLight: 'rgb(241, 242, 246)',
      backgroundLighter: 'rgb(251, 252, 255)'
    },
    colorPrimary: '#264F97', // Dark Blue
    colorPrimaryActive: '#CDD329', // Green
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#f6f7d9', // light green
    colorBackgroundLighter: '#f0f2ba' // very light green
  },

  arair: {
    colorPrimary: '#f18c00', // Orange
    colorPrimaryActive: '#ffa327', // Orange light
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#26A65B', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: '#ffffff', // White
    colorBackgroundLight: '#ffedd6', // light orange
    colorBackgroundLighter: '#f9f2e8' // very light orange
  },

  santelys: {
    colorPrimary: '#014493', // Dark Blue
    colorPrimaryActive: '#23bbec', // Light Blue
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#26A65B', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: '#ffffff', // White
    colorBackgroundLight: '#fcf5c9', // light orange
    colorBackgroundLighter: '#fff8d1' // very light orange
  },

  ads: {
    colorPrimary: '#20524E', // Dark water green
    colorPrimaryActive: '#52cdc5', // water green
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#0b1c1a', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#CDD329', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: `radial-gradient(#f6f7d9, #f0f2ba)`, // degradé vert clair
    colorBackgroundLight: '#b9ebe7', // light water green
    colorBackgroundLighter: '#b9ebe7' // very light orange
  },

  contrast: {
    colorPrimary: '#464851', // Orange
    colorPrimaryActive: '#000000', // Orange light
    colorOtherClear: '#58a3ca', // Blue
    colorBorderDark: '#a29999', // Grey
    colorBorderLight: '#beb5b6', // Grey light
    colorBorderLighter: '#eff0f1', // Grey very light
    colorWhite: '#ffffff', // White
    colorBlack: '#413b3b', // Black
    colorRed: '#EE0000', // Red
    colorGreen: '#26A65B', // Green
    colorOrange: '#FD6A02', // Tiger
    colorBackground: '#ffffff', // White
    colorBackgroundLight: '#ffedd6', // light orange
    colorBackgroundLighter: '#fafafa' // very light orange
  }
};

export default themes;
