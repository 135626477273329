import React from 'react';
import PropTypes from 'prop-types';

import { List, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import Submit from '../Core/Layout/Btn/Submit';

const PaneWrapper = styled.div`
  height: 100%;
  background: ${props => props.theme.colors.backgroundLight};
`;

const StyledList = styled(List)`
  margin: 0 !important;
  background: ${props => props.theme.colors.white};
`;

const StyledEtatItem = styled(List.Item)`
  cursor: pointer;
  position: relative;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.tableRowEvenHover};
  }

  &:before {
    content: '';
    background-color: ${props => props.theme.colors[props.color]};
    height: 100%;
    top: 0;
    width: 10px;
    position: absolute;
  }

  & .content {
    width: 90%
    padding: 10px 20px !important;
  }
`;

const MeetingInfo = styled.div`
  margin-top: 5px;
`;

const RdvInfo = styled.div`
  margin: 0 0 10px;
  font-weight: bold;
`;

const BtnIsadom = styled.a`
  position: absolute;
  top: 50%;
  right: 5px;
  margin: -20px 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;

  padding: 0 20px;
  height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  & i {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  & i.icon {
    margin: 0;
    padding: 0;
  }
`;

const MeetingAddress = styled.div`
  margin: 5px 0 0;
  font-size: 12px;
  text-transform: uppercase;
`;

const MeetingPhone = styled.div`
  margin-top: 5px;

  & a {
    display: inline-block;
    margin: 0 10px 0 0;
    color: ${props => props.theme.colors.purple};
    letter-spacing: 1px;

    &: hover {
      color: ${props => props.theme.colors.purple};
      text-decoration: underline;
    }
  }
`;

const NoMeetingNotice = styled.div`
  padding: 20px 0;
  height: 100%;
  text-align: center;
  background: ${props => props.theme.colors.backgroundLight};
`;

const MeetingList = ({ tMeeting, selectMarker }) => {
  return (
    <PaneWrapper>
      {tMeeting.length > 0 ? (
        <StyledList divided verticalAlign="middle">
          {tMeeting.map(item => {
            let color;
            if (item.bRdvAnnule) {
              color = 'red';
            } else if (item.bRdvTermine) {
              color = 'green';
            } else {
              color = 'grey';
            }
            return (
              <StyledEtatItem
                onMouseOver={() => selectMarker(item.iPKRdv)}
                onFocus={() => selectMarker(item.iPKRdv)}
                onMouseLeave={() => selectMarker(-1)}
                key={`meeting-list-${item.iPKRdv}`}
                color={color}
              >
                <List.Content>
                  <RdvInfo>
                    {item.tRdvDebut} - {item.sLibelle}
                  </RdvInfo>
                  <MeetingInfo>
                    {item.sCodeAnnuaire} - {item.sNomPrenom}
                    <MeetingAddress>{item.sAdresseComplet}</MeetingAddress>
                  </MeetingInfo>
                  <MeetingPhone>
                    <a href={`tel:${item.sTelFixe}`}>{item.sTelFixe}</a>{' '}
                    {item.sTelFixe !== item.sTelGsm ? (
                      <a href={`tel:${item.sTelGsm}`}>{item.sTelGsm}</a>
                    ) : (
                      ''
                    )}{' '}
                    {item.sTelFixe !== item.sTelAutre && item.sTelGsm !== item.sTelAutre ? (
                      <a href={`tel:${item.sTelAutre}`}>{item.sTelAutre}</a>
                    ) : (
                      ''
                    )}
                  </MeetingPhone>
                </List.Content>
                <BtnIsadom href={`isadomlink://annuaire.${parseInt(item.iPKPatient, 0)}`}>
                  <Icon style={{ position: 'relative', left: '-6px' }} name="external alternate" />
                </BtnIsadom>
              </StyledEtatItem>
            );
          })}
        </StyledList>
      ) : (
        <NoMeetingNotice>Sélectionnez un intervenant pour afficher ses rendez-vous</NoMeetingNotice>
      )}
    </PaneWrapper>
  );
};

MeetingList.propTypes = {
  tMeeting: PropTypes.arrayOf(PropTypes.object),
  selectMarker: PropTypes.func.isRequired
};

MeetingList.defaultProps = {
  tMeeting: []
};

export default MeetingList;
