import { CONFIG_LOAD_SUCCESS, CONFIG_LOAD_FAILURE } from '../constants/ActionTypes';

const initialConfigState = {
  googleApiKey: '',
  error: null
};

export default (state = initialConfigState, { type, payload }) => {
  switch (type) {
    case CONFIG_LOAD_SUCCESS: {
      return {
        ...state,
        googleApiKey: payload.googleApiKey,
        error: null
      };
    }
    case CONFIG_LOAD_FAILURE: {
      return { error: payload };
    }
    default:
      return state;
  }
};
